const resources = {
  en: {
    translation: {
      dir: 'ltr',
      // ################################################################
      PrintReceipt: 'Print receipt',
      draftConductionTitle: 'I confirm receiving the draft as specified.',
      Signature: 'Signature',
      Fingerprint: 'Fingerprint',
      'Print Information': 'Print',
      PrintDate: 'Print date',
      ExchangeAddress: 'Exchange address',
      'There is not any transaction id': 'There is not any transaction id',
      'Account Number': 'Account number',
      Balance: 'Balance',
      debtor: 'debtor',
      creditor: 'creditor',
      BalanceReport: 'Balance receipt',
      DearCustomer: 'Dear',
      '،': ',',
      'The remaining balances of your accounts are as follows':
        'the remaining balances of your accounts are as follows:',
      'Currency Type': 'Currency',
      'Total Deposit': 'Total deposit',
      'Total Withdrawal': 'Total withdrawal',
      'Account Remaining': 'Account remaining',
      Status: 'Status',
      Transactions: 'Transactions',
      'Account Status': 'Account Status',
      'Last Checkout': 'Last checkout',
      'Bank Book': 'Bank Book',
      'Branch Code': 'Branch Code',
      Known: 'Known',
      Unknown: 'Unknown',
      'There is not any bank id': 'There is not any bank id',
      'Bank Information': 'Bank Information',
      Row: 'Row',
      Date: 'Date',
      'Transaction Description': 'Transaction description',
      'Receipt Number': 'Receipt Number',
      Deposit: 'Deposit',
      Withdraw: 'Withdraw',
      currency: 'Currency',
      'Bank Deposit': 'Bank deposit',
      'Bank Receipt': 'Bank receipt',
      'Operation Type': 'Operation type',
      'Bank Name': 'Bank name',
      Branch: 'Branch',
      'Customer Name': 'Customer name',
      amount: 'Amount',
      'Transaction Number': 'Transaction number',
      Description: 'Description',
      'The total balance of your account is as follows':
        'The total balance of your account is as follows:',
      BranchName: 'Branch name',
      BranchInformation: 'Branch information',
      Branch2: 'Branch',
      PhoneNumber: 'Phone number',
      'From the beginning': 'From the beginning',
      'Report Date': 'Report date',
      To: 'to',
      'There is not any branch id': 'There is not any branch id',
      'Draft Number': 'Draft Number',
      'Description of draft': 'Description of draft',
      Outgoing: 'outgoing',
      Incoming: 'incoming',
      Commission: 'Commission',
      'Equivalent Amount': 'Equivalent amount',
      'Equivalent Amount Currency': 'Equivalent amount currency',
      TotalDebtor: 'Total Debt',
      TotalCredit: 'Total credit',
      'Cash withdrawal': 'Cash withdrawal',
      'Cash deposit': 'Cash deposit',
      'Cash Name': 'Cash name',
      'Account Owner': 'Account Owner',
      'Received Check': 'Received check',
      'Issued Check': 'Issued check',
      'Document Number': 'Document number',
      Bank: 'Bank',
      'Due Date': 'Due date',
      ChequeStatus: 'Cheque status',
      SavedToCashDeskOrBankAccount: 'Saved to cash desk or bank account',
      SavedToCustomerAccount: 'Saved to customer account',
      ChequeOverallStatus: 'Cheque allover cheque',
      Received: 'Received',
      Paid: 'Paid',
      'Cheques List': 'Cheques List',
      'Document Type': 'Document type',
      'Receive Document Date': 'Receive document date',
      'Account Owner Name': 'Account owner name',
      chequeCurrentPlace: 'Cheque currency place',
      'Customer Information': 'Customer Information',
      Customer: 'Customer',
      'There is not any account number': 'There is not any account number',
      'Customers List': 'Customers List',
      'Father Name': 'Father Name',
      Group: 'Group',
      Account: 'Account',
      Accounts: 'Accounts',
      OverallStatus: 'Overall status',
      'Debtor And Creditor List': 'Debtor And Creditor List',
      DebtCollection: 'Debt collection',
      CreditCollection: 'Credit collection',
      'Draft Orders List': 'Draft Orders List',
      'Other Number': 'Other Number',
      'Draft Amount': 'Draft amount',
      'Draft Currency': 'Draft Currency',
      'Commission Amount': 'Commission amount',
      'Commission Currency': 'Commission currency',
      PaymentPlace: 'Payment Place',
      TradeRate: 'tradeRate',
      ReceivedMoneyAmount: 'Received money amount',
      ReceivedMoneyCurrency: 'Received money currency',
      tradeRate: 'Trade rate',
      'Draft Conduction': 'Draft conduction',
      'Account Name': 'Account name',
      Mobile: 'Mobile',
      Sender: 'Sender',
      Receiver: 'Receiver',
      Source: 'Source',
      'Document Number2': 'Document number',
      Type: 'Type',
      OutgoingDraft: 'Outgoing draft',
      IncomingDraft: 'Incoming draft',
      Address: 'Address',
      'Buy Amount': 'Buy amount',
      'Sell Amount': 'Sell amount',
      'Sell Currency': 'Sell currency',
      'buy currency': 'Buy currency',
      'Transfer Between Accounts': 'Transfer between accounts',
      CashedToMoney: 'Cashed to money',
      HasReturned: 'Has Returned',
      Drafts: 'Drafts'
    }
  },
  fa: {
    translation: {
      dir: 'rtl',
      // ###########################################################################
      PrintReceipt: 'چاپ رسید',
      draftConductionTitle:
        'اینجانب، به مشخصات ذکرشده، حواله خود را دریافت نمودم.',
      Signature: 'امضا',
      Fingerprint: 'اثر انگشت',
      'Print Information': 'چاپ اطلاعات',
      PrintDate: 'تاریخ چاپ',
      ExchangeAddress: 'آدرس صرافی',
      'There is not any transaction id': 'هیچ شناسه تراکنشی وجود ندارد.',
      'Account Number': 'شماره حساب',
      Balance: 'باقی‌مانده',
      debtor: 'بدهکار',
      creditor: 'طلبکار',
      BalanceReport: 'رسید باقی‌مانده',
      DearCustomer: 'مشتری گرامی',
      '،': '،',
      'The remaining balances of your accounts are as follows':
        'باقی‌مانده حساب‌های شما به شرح زیر می‌باشد:',
      'Currency Type': 'نوع ارز',
      'Total Deposit': 'مجموع واریزی',
      'Total Withdrawal': 'جمع برداشت',
      'Account Remaining': 'باقی‌مانده حساب',
      Status: 'وضعیت',
      Transactions: 'معامله‌ها',
      'Account Status': 'وضعیت حساب',
      'Last Checkout': 'آخرین تسویه',
      'Bank Book': 'دفتر بانک',
      'Branch Code': 'کد شعبه',
      Known: 'معلوم',
      Unknown: 'نامعلوم',
      'There is not any bank id': 'هیچ شناسه بانکی وجود ندارد.',
      'Bank Information': 'اطلاعات بانک',
      Row: 'ردیف',
      Date: 'تاریخ',
      'Transaction Description': 'شرح تراکنش',
      'Receipt Number': 'شماره فیش',
      Deposit: 'واریز',
      Withdraw: 'برداشت',
      currency: 'ارز',
      'Bank Deposit': 'واریز بانکی',
      'Bank Receipt': 'دریافت بانکی',
      'Operation Type': 'نوع عملیات',
      'Bank Name': 'نام بانک',
      Branch: 'شعبه',
      'Customer Name': 'نام مشتری',
      amount: 'مبلغ',
      'Transaction Number': 'شماره تراکنش',
      Description: 'توضیحات',
      'The total balance of your account is as follows':
        'جمع مانده حساب شما به صورت زیر می‌باشد:',
      BranchName: 'نام نمایندگی',
      BranchInformation: ' اطلاعات نمایندگی',
      Branch2: 'نمایندگی',
      PhoneNumber: 'شماره تلفن',
      'From the beginning': 'از ابتدا',
      'Report Date': 'تاریخ گزارش',
      To: 'تا',
      'There is not any branch id': 'هیچ شناسه نمایندگی‌ای وجود ندارد.',
      'Draft Number': 'شماره حواله',
      'Description of draft': 'شرح حواله',
      Outgoing: 'رفت',
      Incoming: 'آمد',
      Commission: 'کارمزد',
      'Equivalent Amount': 'مبلغ معادل',
      'Equivalent Amount Currency': 'ارز مبلغ معادل',
      TotalDebtor: 'جمع بدهکاری',
      TotalCredit: 'جمع طلبکاری',
      'Cash withdrawal': 'پرداخت نقدی',
      'Cash deposit': 'دریافت نقدی',
      'Cash Name': 'نام صندوق',
      'Account Owner': 'صاحب حساب',
      'Received Check': 'چک دریافتی',
      'Issued Check': 'چک پرداختی',
      'Document Number': 'شماره سند',
      Bank: 'بانک',
      'Due Date': 'تاریخ سررسید',
      ChequeStatus: 'وضعیت چک',
      SavedToCashDeskOrBankAccount: 'خوابانده شده',
      SavedToCustomerAccount: 'واگذار شده',
      ChequeOverallStatus: 'وضعیت کلی چک',
      Received: 'دریافتی',
      Paid: 'پرداختی',
      'Cheques List': 'لیست چک‌ها',
      'Document Type': 'نوع سند',
      'Receive Document Date': 'تاریخ دریافت سند',
      'Account Owner Name': 'نام صاحب حساب',
      chequeCurrentPlace: 'مکان فعلی چک',
      'Customer Information': 'اطلاعات مشتری',
      Customer: 'مشتری',
      'There is not any account number': 'هیچ شماره حسابی وجود ندارد.',
      'Customers List': 'لیست مشتریان',
      'Father Name': 'نام پدر',
      Group: 'گروه',
      Account: 'حساب',
      Accounts: 'حساب‌ها',
      OverallStatus: 'وضعیت کلی',
      'Debtor And Creditor List': 'لیست بدهکار و طلبکار',
      DebtCollection: 'جمع بدهی',
      CreditCollection: 'جمع طلب',
      'Draft Orders List': 'لیست سفارشات حواله',
      'Other Number': 'شماره متفرقه',
      'Draft Amount': 'مبلغ حواله',
      'Draft Currency': 'ارز حواله',
      'Commission Amount': 'مبلغ کارمزد',
      'Commission Currency': 'ارز کارمزد',
      PaymentPlace: 'محل پرداخت',
      ReceivedMoneyAmount: 'مبلغ دریافتی',
      ReceivedMoneyCurrency: 'ارز مبلغ دریافتی',
      tradeRate: 'نرخ',
      'Draft Conduction': 'اجرای حواله',
      'Account Name': 'نام حساب',
      Mobile: 'موبایل',
      Sender: 'فرستنده',
      Receiver: 'گیرنده',
      Source: 'منبع',
      'Document Number2': 'شماره سند',
      Type: 'نوع',
      OutgoingDraft: 'حواله رفت',
      IncomingDraft: 'حواله آمد',
      Address: 'آدرس',
      'Buy Amount': 'مبلغ خرید',
      'Sell Amount': 'مبلغ فروش',
      'Sell Currency': 'فروش ارز',
      'buy currency': 'خرید ارز',
      'Transfer Between Accounts': 'انتقال بین حساب',
      CashedToMoney: 'پاس شده',
      HasReturned: 'برگشت خورده',
      Drafts: 'حواله‌ها'
    },
    dir: 'rtl'
  },
  fad: {
    translation: {
      dir: 'rtl',
      // ########################################
      PrintReceipt: 'چاپ رسید',
      draftConductionTitle:
        'اینجانب، با مشخصات ذکرشده، حواله خود را دریافت کردم.',
      Signature: 'امضا',
      Fingerprint: 'اثر انگشت',
      'Print Information': 'چاپ اطلاعات',
      PrintDate: 'تاریخ چاپ',
      ExchangeAddress: 'آدرس صرافی',
      'There is not any transaction id': 'هیچ شناسه معامله‌ای وجود ندارد.',
      'Account Number': 'شماره حساب',
      Balance: 'بیلانس',
      debtor: 'باقی‌دار',
      creditor: 'طلبکار',
      BalanceReport: 'رسید بیلانس',
      DearCustomer: 'مشتری گرامی',
      '،': '،',
      'The remaining balances of your accounts are as follows':
        'باقی‌مانده حساب‌های شما به شرح زیر می‌باشد:',
      'Currency Type': 'نوع ارز',
      'Total Deposit': 'مجموع واریزی',
      'Total Withdrawal': 'جمع برداشت',
      'Account Remaining': 'بیلانس حساب',
      Status: 'وضعیت',
      Transactions: 'معاملات',
      'Account Status': 'وضعیت حساب',
      'Last Checkout': 'آخرین تسویه',
      'Bank Book': 'دفتر بانک',
      'Branch Code': 'کد شعبه',
      Known: 'معلوم',
      Unknown: 'نامعلوم',
      'There is not any bank id': 'هیچ شناسه بانکی وجود ندارد.',
      'Bank Information': 'اطلاعات بانک',
      Row: 'ردیف',
      Date: 'تاریخ',
      'Transaction Description': 'شرح معامله',
      'Receipt Number': 'شماره فیش',
      Deposit: 'واریز',
      Withdraw: 'برداشت',
      currency: 'ارز',
      'Bank Deposit': 'واریز بانکی',
      'Bank Receipt': 'دریافت بانکی',
      'Operation Type': 'نوع عملیات',
      'Bank Name': 'نام بانک',
      Branch: 'شعبه',
      'Customer Name': 'نام مشتری',
      amount: 'مبلغ',
      'Transaction Number': 'شماره معامله',
      Description: 'توضیحات',
      'The total balance of your account is as follows':
        'جمع مانده حساب شما به صورت زیر می‌باشد:',
      BranchName: 'نام نمایندگی',
      BranchInformation: ' اطلاعات نمایندگی',
      Branch2: 'نمایندگی',
      PhoneNumber: 'شماره تلفن',
      'From the beginning': 'از آغاز',
      'Report Date': 'تاریخ گزارش',
      To: 'تا',
      'There is not any branch id': 'هیچ شناسه منایندگی‌ای وجود ندارد.',
      'Draft Number': 'شماره حواله',
      'Description of draft': 'شرح حواله',
      Outgoing: 'رفت',
      Incoming: 'آمد',
      Commission: 'کمیشن',
      'Equivalent Amount': 'مبلغ معادل',
      'Equivalent Amount Currency': 'ارز مبلغ معادل',
      TotalDebtor: 'جمع بدهکاری',
      TotalCredit: 'جمع طلبکاری',
      'Cash withdrawal': 'پرداخت نقدی',
      'Cash deposit': 'دریافت نقدی',
      'Cash Name': 'نام خزانه',
      'Account Owner': 'صاحب حساب',
      'Received Check': 'چک دریافت‌شده',
      'Issued Check': 'چک پرداخت‌شده',
      'Document Number': 'شماره سند',
      Bank: 'بانک',
      'Due Date': 'تاریخ سررسید',
      ChequeStatus: 'وضعیت چک',
      SavedToCashDeskOrBankAccount: 'ذخیره شده',
      SavedToCustomerAccount: 'واگذار شده',
      ChequeOverallStatus: 'وضعیت کلی چک',
      Received: 'دریافتی',
      Paid: 'پرداختی',
      'Cheques List': 'فهرست چک‌ها',
      'Document Type': 'نوع سند',
      'Receive Document Date': 'تاریخ دریافت سند',
      'Account Owner Name': 'نام صاحب حساب',
      chequeCurrentPlace: 'محل فعلی چک',
      'Customer Information': 'اطلاعات مشتری',
      Customer: 'مشتری',
      'There is not any account number': 'هیچ شماره حسابی وجود ندارد.',
      'Customers List': 'فهرست مشتریان',
      'Father Name': 'نام پدر',
      Group: 'گروه',
      Account: 'حساب',
      Accounts: 'حساب‌ها',
      OverallStatus: 'وضعیت کلی',
      'Debtor And Creditor List': 'فهرست باقی‌داران و طلبکاران',
      DebtCollection: 'جمع بدهی',
      CreditCollection: 'جمع طلب',
      'Draft Orders List': 'فهرست سفارشات حواله',
      'Other Number': 'شماره متفرقه',
      'Draft Amount': 'مبلغ حواله',
      'Draft Currency': 'ارز حواله',
      'Commission Amount': 'مبلغ کمیشن',
      'Commission Currency': 'ارز کمیشن',
      PaymentPlace: 'محل پرداخت',
      ReceivedMoneyAmount: 'مبلغ دریافتی',
      ReceivedMoneyCurrency: 'ارز مبلغ دریافتی',
      tradeRate: 'نرخ',
      'Draft Conduction': 'اجرای حواله',
      'Account Name': 'نام حساب',
      Mobile: 'موبایل',
      Sender: 'فرستنده',
      Receiver: 'گیرنده',
      Source: 'منبع',
      'Document Number2': 'نمبر سند',
      Type: 'نوع',
      OutgoingDraft: 'حواله صادره',
      IncomingDraft: 'حواله وارده',
      Address: 'آدرس',
      'Buy Amount': 'مبلغ خرید',
      'Sell Amount': 'مبلغ فروش',
      'Sell Currency': 'فروش ارز',
      'buy currency': 'خرید ارز',
      'Transfer Between Accounts': 'انتقال بین حساب',
      CashedToMoney: 'پاس شده',
      HasReturned: 'برگشت خورده',
      Drafts: 'حواله‌ها'
    },
    dir: 'rtl'
  },
  fap: {
    translation: {
      dir: 'rtl',
      PrintReceipt: 'د رسید چاپ',
      draftConductionTitle:
        'زه، د پورته ذکر شویو مشخصاتو سره، خپل حواله ترلاسه کړ.',
      Signature: 'لاسلیک',
      Fingerprint: 'د ګوتې نښه',
      'Print Information': 'معلومات چاپ کړئ',
      PrintDate: 'د چاپ نېټه',
      ExchangeAddress: 'د صرافۍ پته',
      'There is not any transaction id': 'د معاملې هیڅ پیژند شمېره نشته.',
      'Account Number': 'د حساب شمېره',
      Balance: 'باقي اندازه',
      debtor: 'به نام',
      creditor: 'جمع',
      BalanceReport: 'د بیلانس رسید',
      DearCustomer: 'ګرانه مشتریه',
      '،': '،',
      'The remaining balances of your accounts are as follows':
        'ستاسو د حسابونو پاتې‌بیلانس په لاندې ډول دی:',
      'Currency Type': 'د اسعارو ډول',
      'Total Deposit': 'ټولیز جمع یا ټولیز واریز',
      'Total Withdrawal': ' د برداشت ټولیزه',
      'Account Remaining': 'د حساب پاتې اندازه',
      Status: 'حالت',
      Transactions: 'تراکنشونه',
      'Account Status': 'د حساب حالت',
      'Last Checkout': 'آخرین تسویه',
      'Bank Book': 'د بانک کتاب',
      'Branch Code': 'د څانګې کوډ',
      Known: 'معلوم',
      Unknown: 'نامعلوم',
      'There is not any bank id': 'د بانک هیڅ پیژند شمېره نشته.',
      'Bank Information': 'د بانک معلومات',
      Row: 'لیست',
      Date: 'نېټه',
      'Transaction Description': 'د معاملې تشریح',
      'Receipt Number': 'د رسید شمېره',
      Deposit: 'جمع کول',
      Withdraw: 'بیرته اخیستل',
      currency: 'اسعار',
      'Bank Deposit': 'بانکي واریز',
      'Bank Receipt': 'بانکي ترلاسه کول',
      'Operation Type': 'د عملیات ډول',
      'Bank Name': 'د بانک نوم',
      Branch: 'څانګه',
      'Customer Name': 'د مشتری نوم',
      amount: 'اندازه',
      'Transaction Number': 'د تراکنش شمېره',
      Description: 'تشریح',
      'The total balance of your account is as follows':
        'ستاسو د حساب ټولیز پاتې‌بیلانس په لاندې ډول دی:',
      BranchName: 'د نمایندګۍ نوم',
      BranchInformation: 'د نمایندګۍ معلومات',
      Branch2: 'څانګه',
      PhoneNumber: 'د ټلیفون شمېره',
      'From the beginning': 'له پیل څخه',
      'Report Date': 'د راپور نېټه',
      To: 'تر',
      'There is not any branch id': 'د څانګې هیڅ پیژند شمېره نشته.',
      'Draft Number': 'د حوالې شمېره',
      'Description of draft': 'د حوالې تشریح',
      Outgoing: 'صادر شوی',
      Incoming: 'وارد شوی',
      Commission: 'کمیشن',
      'Equivalent Amount': 'برابر اندازه',
      'Equivalent Amount Currency': 'د برابر اندازه اسعار',
      TotalDebtor: 'د پور ټولیزه',
      TotalCredit: 'د پور ورکولو ټولیزه',
      'Cash withdrawal': 'نغدي پیسې اخیستل',
      'Cash deposit': 'نغدي پیسې جمع کړئ',
      'Cash Name': 'د صندوق نوم',
      'Account Owner': 'د حساب مالک',
      'Received Check': 'ترلاسه شوې چیک',
      'Issued Check': 'ورکړې شوې چیک',
      'Document Number': 'د سند شمېره',
      Bank: 'بانک',
      'Due Date': 'د سررسید نېټه',
      ChequeStatus: 'د چک حالت',
      SavedToCashDeskOrBankAccount: 'په نغدي صندوق یا بانک کې ثبت شوی',
      SavedToCustomerAccount: 'مشتری ته وسپارل شو',
      ChequeOverallStatus: 'د چک ټولیز حالت',
      Received: 'ترلاسه شوی',
      Paid: 'تادیه شوی',
      'Cheques List': 'د چکونو لیست',
      'Document Type': 'د سند ډول',
      'Receive Document Date': 'د سند ترلاسه کولو نېټه',
      'Account Owner Name': 'د حساب مالک نوم',
      chequeCurrentPlace: 'د چیک فعلي ځای',
      'Customer Information': 'د مشتری معلومات',
      Customer: 'مشتری',
      'There is not any account number': 'هیڅ حساب شمېره نشته.',
      'Customers List': 'د مشتریانو لیست',
      'Father Name': 'د پلار نوم',
      Group: 'ډله',
      Account: 'حساب',
      Accounts: 'حسابونه',
      OverallStatus: 'ټولیز حالت',
      'Debtor And Creditor List': 'د پورمن او طلبکار لیست',
      DebtCollection: 'د پور ټولګه',
      CreditCollection: 'د طلب ټولګه',
      'Draft Orders List': 'د حوالې فرمایشونو لیست',
      'Other Number': 'بله شمېره',
      'Draft Amount': 'د حوالې اندازه',
      'Draft Currency': 'د حوالې اسعار',
      'Commission Amount': 'د کمیشن اندازه',
      'Commission Currency': 'د کمیسیون ارز',
      PaymentPlace: 'د تادیې ځای',
      ReceivedMoneyAmount: 'ترلاسه شوې اندازه',
      ReceivedMoneyCurrency: 'د ترلاسه شوې مبلغې ارز',
      tradeRate: 'نرخ',
      'Draft Conduction': 'د حوالې اجرا',
      'Account Name': 'د حساب نوم',
      Mobile: 'موبایل',
      Sender: 'لېږدونکی',
      Receiver: 'ترلاسه کونکی',
      Source: 'پیسو',
      'Document Number2': 'سند شمېره',
      Type: 'ډول',
      OutgoingDraft: 'صادر شوی حواله',
      IncomingDraft: 'وارد شوی حواله',
      Address: 'پته',
      'Buy Amount': 'د پېر اندازه',
      'Sell Amount': 'د پلور اندازه',
      'Sell Currency': 'د اسعارو پلور',
      'buy currency': 'اسعار واخلئ',
      'Transfer Between Accounts': 'تر منځ حسابونه لیږد',
      CashedToMoney: 'نغد شوی',
      HasReturned: 'بېرته راستون شوی',
      Drafts: 'حوالې'
    },
    dir: 'rtl'
  }
};

export default resources;
