import Num2persian from 'num2persian';
import { toWords as Num2English } from 'number-to-words'; // For English translation
import React from 'react';
import { getQueryStringsFromLocation } from 'spec';
import * as XLSX from 'xlsx';
import { formatMoneyAmount } from '../components/AryNumberFormatter';

export function getMoonUtcTime(date) {
  return Date.UTC(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    12,
    0,
    0,
    0
  ).toString();
}
export function getTimeZoneFormattedDate(
  date,
  isGregorian,
  timeZone,
  isDigitsLatin
) {
  const locale = isGregorian ? 'en-ZA' : 'fa-IR';
  const numberingSystem = isDigitsLatin ? 'latn' : null;
  const localeDateString = new Date(+date).toLocaleDateString(locale, {
    timeZone,
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    numberingSystem
  });
  return localeDateString;
}
export function getTimeZoneFormattedTime(
  date,
  isGregorian = false,
  timeZone = 'Asia/Tehran',
  isDigitsLatin = true
) {
  const formattedDate = getTimeZoneFormattedDate(
    date,
    isGregorian,
    timeZone,
    isDigitsLatin
  );
  const locale = isGregorian ? 'en-ZA' : 'fa-IR';
  const numberingSystem = isDigitsLatin ? 'latn' : undefined;
  const formattedTime = new Date(+date).toLocaleTimeString(locale, {
    timeZone,
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false,
    numberingSystem
  });
  return `${formattedDate} - ${formattedTime}`;
}
export function translatePhraseItem(t, phraseItem) {
  if (!phraseItem || !t) return null;
  const finalText = phraseItem.shouldTranslate
    ? t(phraseItem.text.toLowerCase().trim())
    : phraseItem.text;
  return finalText;
}
export function formatQueryString(queryStringsList) {
  let formattedData = '';

  if (!queryStringsList?.length) return '';
  const clearQueryStringsList = queryStringsList.filter((obj) => obj.value);
  const formattedQueryStringsList = clearQueryStringsList.map(
    (item) => `${item.key}=${item.value}`
  );

  formattedData = formattedQueryStringsList.join('&');

  return formattedData ? `?${formattedData}` : '';
}
export function getQueryStringVariable(variable) {
  const query = getQueryStringsFromLocation();
  const vars = query.split('&');
  for (let i = 0; i < vars.length; i++) {
    const pair = vars[i].split('=');
    if (decodeURIComponent(pair[0]) === variable) {
      return decodeURIComponent(pair[1]);
    }
  }
  return null;
}
export function parseAccessToken(token) {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
      .join('')
  );

  return JSON.parse(jsonPayload);
}
function getConditionalHookResult(text, hookValue) {
  const pureText = text.substr(1, text.length - 2);
  const value = pureText.split('|')[1];
  return value.split('//')[+hookValue];
}

function parseSinglePrefixes(prefixes, hookValue, matchedValue, t) {
  if (prefixes.length === 0) return hookValue;

  let finalText = hookValue;

  // todo: throw exception when exception occurred!

  if (prefixes.includes('!')) {
    finalText = t(finalText);
  }
  if (prefixes.includes('$')) {
    const decimalPlaces = getDecimalPlaces(hookValue);
    finalText = formatMoneyAmount(finalText, decimalPlaces);
  }
  if (prefixes.includes('*')) {
    finalText = getTimeZoneFormattedTime(finalText);
  }
  if (prefixes.includes('@')) {
    finalText = getTimeZoneFormattedDate(finalText);
  }
  if (prefixes.includes('?')) {
    finalText = finalText || t('Unknown');
  }
  if (prefixes.includes('&')) {
    finalText = finalText ? `(${finalText})` : '';
  }
  if (prefixes.includes('%')) {
    finalText = getConditionalHookResult(matchedValue, hookValue);
  }
  return finalText;
}

function getDecimalPlaces(matchedValue) {
  const decimalRegex = /\.\d+/;
  const decimalMatches = matchedValue.match(decimalRegex);
  if (decimalMatches) {
    return decimalMatches[0].length - 1;
  }
  return 0;
}

function getParsedHookValue(matchedValue, hookName, hookValue, t) {
  if (!hookValue) return '';
  const prefixes = matchedValue.substr(1, matchedValue.indexOf(hookName) - 1);
  if (matchedValue.includes('#')) {
    return []
      .concat(hookValue)
      .map((elem) => parseSinglePrefixes(prefixes, elem, matchedValue, t))
      .join(`${t('Comma')} `);
  }
  return parseSinglePrefixes(prefixes, hookValue, matchedValue, t);
}

function checkForRecursiveTemplates(template, metadata, t) {
  const regexStr = '<\\{[^>]*\\}>';
  const regex = new RegExp(regexStr, 'g');
  const matchedArray = template.match(regex);
  if (matchedArray) {
    const recursiveTemplate = matchedArray[0];
    const hookDetectorRegexStr = '\\{[^\\}]+\\}';
    const hookDetectorRegex = new RegExp(hookDetectorRegexStr, 'g');
    const hooksArray = recursiveTemplate
      .substr(2, recursiveTemplate.length - 4)
      .match(hookDetectorRegex)
      .map((hook) => hook.match(/[-a-zA-Z]+/)[0]);
    const desiredMetadata = hooksArray.map((hookName) => ({
      [hookName]: metadata.filter((obj) => obj.hook === hookName)
    }));
    const iterationCount = Object.values(desiredMetadata[0])[0].length;
    const parsedIterations = Array(iterationCount).fill(recursiveTemplate);
    for (let i = 0; i < iterationCount; i++) {
      const currentMetadata = desiredMetadata
        .map((obj) => ({
          [Object.keys(obj)[0]]: Object.values(obj)[0][i]
        }))
        .map((elem) => Object.values(elem)[0]);
      currentMetadata.forEach((hookObj) => {
        const innerRegexStr = `\\{[^(\\{\\}a-zA-Z)]*${hookObj.hook}(\\|[^\\}]*)?\\}`;
        const innerRegex = new RegExp(innerRegexStr, 'g');
        if (parsedIterations[i].match(innerRegex)) {
          const matchedString = parsedIterations[i].match(innerRegex)[0];
          parsedIterations[i] = parsedIterations[i].replace(
            innerRegex,
            getParsedHookValue(matchedString, hookObj.hook, hookObj.value, t)
          );
        } else {
          console.info(`${innerRegex} not found!`);
        }
      });
    }
    const parsedTemplate = parsedIterations
      .map((parsedIteration) =>
        parsedIteration.substr(2, parsedIteration.length - 4)
      )
      .join(`${t('Comma')} `);
    const remainingMetadata = metadata.filter(
      (obj) => hooksArray.indexOf(obj.hook) === -1
    );
    return checkForRecursiveTemplates(
      template.replace(regex, parsedTemplate),
      remainingMetadata
    );
  }
  return [template, metadata];
}

export function parseDescriptionTemplate(templates, t, tId, metadata) {
  if (!templates?.length || !tId) return '';
  const parsedTemplate = templates.find((obj) => obj.id === tId);
  let parsedDescription = parsedTemplate?.body;
  if (!parsedTemplate) return `No related template found with ${tId}`;

  let remainingMetadata = metadata;

  [parsedDescription, remainingMetadata] = checkForRecursiveTemplates(
    parsedDescription,
    metadata,
    t
  );

  const clearMetadata = [];
  remainingMetadata.forEach((hookObj) => {
    const hookIndex = clearMetadata.findIndex(
      (obj) => obj.hook === hookObj.hook
    );
    if (hookIndex === -1) {
      clearMetadata.push({ ...hookObj });
    } else {
      clearMetadata[hookIndex].value = []
        .concat(clearMetadata[hookIndex].value)
        .concat(hookObj.value);
    }
  });

  clearMetadata.forEach((hookObj) => {
    const regexStr = `\\{[^(\\}a-zA-Z)]*${hookObj.hook}(\\|[^\\}]*)?\\}`;
    const regex = new RegExp(regexStr, 'g');
    if (parsedDescription.match(regex)) {
      const matchedString = parsedDescription.match(regex)[0];
      parsedDescription = parsedDescription.replace(
        regex,
        getParsedHookValue(matchedString, hookObj.hook, hookObj.value, t)
      );
    } else {
      console.info(`${regex} not found!`);
    }
  });
  return parsedDescription.trim();
}
export function getCurrencyAmountDigitReportDescription(amount, currencyCode) {
  return amount ? `${formatMoneyAmount(amount)} ${currencyCode}` : 0;
}
export function getCurrencyAmountWordReportDescription(
  amount,
  currencyCode,
  lang
) {
  const amountString =
    lang === 'en' ? Num2English(amount) : Num2persian(amount);
  return amount ? `${amountString} ${currencyCode}` : amountString;
}

function getReportIndexedDB() {
  return new Promise((resolve, reject) => {
    let db;
    const request = window.indexedDB.open('ReportDatabase', 1);
    request.onerror = (event) => {
      console.error(`IndexedDB error: ${event.target.errorCode}`);
      reject(event.target.errorCode);
    };
    request.onupgradeneeded = (event) => {
      switch (event.oldVersion) {
        case 0: {
          db = request.result;
          if (!db.objectStoreNames.contains('companyLogo')) {
            db.createObjectStore('companyLogo', { keyPath: 'fileId' });
          }
          break;
        }
        default:
      }
    };

    request.onsuccess = () => {
      db = request.result;
      resolve(db);
    };
  });
}
function addCompanyLogoFileToDB(db, downloadFile, fileId) {
  return new Promise((resolve, reject) => {
    downloadFile([{ key: 'fileId', value: fileId }]).then(
      (downloadFileQueryData) => {
        if (downloadFileQueryData) {
          const transaction = db.transaction('companyLogo', 'readwrite');
          const store = transaction.objectStore('companyLogo');
          store.clear();
          const addTransaction = store.add({
            file: downloadFileQueryData,
            fileId
          });
          addTransaction.onerror = () => {
            console.error(addTransaction.error);
            reject(addTransaction.error);
          };
          addTransaction.onsuccess = () => {
            resolve(downloadFileQueryData);
          };
        } else {
          resolve(downloadFileQueryData);
        }
      }
    );
  });
}
function getCompanyLogoFileFromDB(db, fileId) {
  return new Promise((resolve, reject) => {
    const transaction = db.transaction('companyLogo', 'readonly');
    const store = transaction.objectStore('companyLogo');
    const getTransaction = store.get(fileId);
    getTransaction.onerror = () => {
      console.error(getTransaction.error);
      reject(getTransaction.error);
    };
    getTransaction.onsuccess = () => {
      resolve(getTransaction.result);
    };
  });
}

export async function getCompanyLogo(downloadFile, fileId) {
  const ARIYANA_LOGO_PATH = '/static/img/brands/moneyx.png';
  if (!fileId) {
    return null;
  }
  const db = await getReportIndexedDB();
  const existingFile = await getCompanyLogoFileFromDB(db, fileId);
  if (existingFile?.fileId === fileId) {
    return URL.createObjectURL(existingFile.file);
  }
  const companyLogo = await addCompanyLogoFileToDB(db, downloadFile, fileId);
  if (companyLogo) {
    return URL.createObjectURL(companyLogo);
  }
  return ARIYANA_LOGO_PATH;
}

const extractTextFromElement = (element) => {
  if (!React.isValidElement(element)) return String(element || '');

  const { children } = element.props || {};
  if (typeof children === 'string') return children;
  if (Array.isArray(children)) {
    return children
      .map((child) => extractTextFromElement(child))
      .filter(Boolean)
      .join(' ');
  }
  return '';
};

const prepareDataForExcel = (
  transactions,
  columns,
  getCurrencyDecimalPlaces
) => {
  const columnKeys = Object.keys(columns || {});
  const headers = columnKeys
    .map((key) => ({
      label: columns[key].label,
      key: columns[key].key || key,
      renderCell: columns[key].renderCell,
      dataType: columns[key].dataType || 'string'
    }))
    .reverse();

  const excelData = [];
  const metadata = [];

  transactions.forEach((transaction, index) => {
    const rowData = {};
    headers.forEach(({ label, key, renderCell, dataType }) => {
      let value;
      if (renderCell) {
        const rendered = renderCell(transaction, key);
        if (typeof rendered === 'object' && rendered !== null) {
          value = extractTextFromElement(rendered);
        } else {
          value = rendered !== undefined ? rendered : transaction[key] || '';
        }
      } else {
        value = transaction[key] || '';
      }

      if (value === '\u200F-') {
        rowData[label] = value;
      } else if (dataType === 'number') {
        value = String(value).replace(/,/g, '');
        value = Number(value);
        value = Number.isNaN(value) ? 0 : value;
        rowData[label] = value;
      } else {
        rowData[label] = String(value);
      }
    });

    // فیلتر کردن کلیدهای ناخواسته از rowData
    Object.keys(rowData).forEach((key) => {
      if (key.toLowerCase() === 'decimalplaces') {
        delete rowData[key];
      }
    });
    delete rowData._showPlusSignForBalance;

    excelData.push(rowData);

    // ذخیره متادیتا تو آرایه جداگانه
    const currencyName = transaction.currencyTitle || 'default';
    metadata[index] = {
      _showPlusSignForBalance: transaction._showPlusSignForBalance,
      DecimalPlaces: getCurrencyDecimalPlaces({ title: currencyName }) || 2
    };
  });

  return { excelData, headers, metadata };
};

const applyRightAlignment = (worksheet, excelData, headers, metadata) => {
  const range = XLSX.utils.decode_range(worksheet['!ref']);

  for (let R = range.s.r; R <= range.e.r; ++R) {
    for (let C = range.s.c; C <= range.e.c; ++C) {
      const cellAddress = XLSX.utils.encode_cell({ r: R, c: C });
      const cell = worksheet[cellAddress];
      if (cell) {
        if (!cell.s) cell.s = {};
        if (!cell.s.alignment) cell.s.alignment = {};
        cell.s.alignment.horizontal = 'right';
        if (!cell.s.font) cell.s.font = { name: 'Tahoma' };
        if (R === 0) {
          if (!cell.s.font) cell.s.font = {};
          cell.s.font.bold = true;
        }
        if (cell.t === 'n') {
          const columnLabel =
            worksheet[XLSX.utils.encode_cell({ r: 0, c: C })]?.v;
          const header = headers.find((h) => h.label === columnLabel);
          if (header && header.dataType === 'number') {
            const meta = metadata[R - 1] || {};
            const decimalPlaces = Math.min(
              Math.max(Math.floor(meta.DecimalPlaces) || 2, 0),
              6
            );
            if (cell.v === undefined || Number.isNaN(cell.v)) {
              cell.v = 0;
            }
            if (cell.v === 0) {
              cell.w = '0';
            } else {
              const hasNonZeroDecimal = cell.v % 1 !== 0;
              let formatString;
              if (header.key === 'balance') {
                const showPlusSign = meta._showPlusSignForBalance === true;
                if (showPlusSign && cell.v > 0) {
                  formatString = hasNonZeroDecimal
                    ? `+#,##0.${'0'.repeat(decimalPlaces)};-#,##0.${'0'.repeat(
                        decimalPlaces
                      )};+0`
                    : '+#,##0;-#,##0;+0';
                } else {
                  formatString = hasNonZeroDecimal
                    ? `#,##0.${'0'.repeat(decimalPlaces)};-#,##0.${'0'.repeat(
                        decimalPlaces
                      )};0`
                    : '#,##0;-#,##0;0';
                }
              } else {
                formatString = hasNonZeroDecimal
                  ? `#,##0.${'0'.repeat(decimalPlaces)};-#,##0.${'0'.repeat(
                      decimalPlaces
                    )};0`
                  : '#,##0;-#,##0;0';
              }
              cell.z = formatString;
            }
          }
        }
        if (cell.t === 's') {
          cell.w = cell.v;
        }
      }
    }
  }
};

export const exportToExcel = (
  columns,
  accountStatusColumns,
  reportName,
  cellWidth,
  data,
  getCurrencyDecimalPlaces
) => {
  const workbook = XLSX.utils.book_new();

  if (
    data.accountStatus &&
    Array.isArray(data.accountStatus) &&
    data.accountStatus.length > 0
  ) {
    const {
      excelData: accountStatusData,
      headers: statusHeaders,
      metadata: statusMetadata
    } = prepareDataForExcel(
      data.accountStatus,
      accountStatusColumns,
      getCurrencyDecimalPlaces
    );
    const accountStatusWorksheet = XLSX.utils.json_to_sheet(accountStatusData);
    applyRightAlignment(
      accountStatusWorksheet,
      accountStatusData,
      statusHeaders,
      statusMetadata
    );
    accountStatusWorksheet['!cols'] = statusHeaders
      .map(() => ({ wch: 20 }))
      .reverse();
    XLSX.utils.book_append_sheet(
      workbook,
      accountStatusWorksheet,
      'وضعیت حساب'
    );
  }

  if (
    data.transactions &&
    Array.isArray(data.transactions) &&
    data.transactions.length > 0
  ) {
    const {
      excelData: transactionsExcelData,
      headers: transHeaders,
      metadata: transMetadata
    } = prepareDataForExcel(
      data.transactions,
      columns,
      getCurrencyDecimalPlaces
    );
    const transactionsWorksheet = XLSX.utils.json_to_sheet(
      transactionsExcelData
    );
    applyRightAlignment(
      transactionsWorksheet,
      transactionsExcelData,
      transHeaders,
      transMetadata
    );
    transactionsWorksheet['!cols'] = transHeaders
      .map(() => ({ wch: cellWidth || 25 }))
      .reverse();
    XLSX.utils.book_append_sheet(workbook, transactionsWorksheet, 'معاملات');
  }

  XLSX.writeFile(workbook, `${reportName || 'گزارش‌ها'}.xlsx`, {
    bookType: 'xlsx',
    type: 'binary',
    compression: true
  });
};
